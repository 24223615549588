<template>
  <div>
    <d-body-top-bar
      :title="$t('views.client.details.body.left.enzocard-access.history-access')"
      :class="$store.getters['layout/getInnerWidth'] < 1332 ? 'mt-3' : ''"
    >
      <div slot="tree">
        <label
          class="inactive-tree link-area pointer"
          @click="$router.push({ name: 'live-accesses'})"
        >
          {{ $t('views.live-accesses.index.title') }} /
        </label>
        <label class="bg-doinsport-primary ml-1">
          {{ $t('views.client.details.body.left.enzocard-access.history-access') }}
        </label>
      </div>
    </d-body-top-bar>
    <accesses-card/>
  </div>
</template>
<script>

import AccessesCard from "@custom/accesses/history/AccessesCard";

export default {
  components: {AccessesCard}
}
</script>
