import {_get, clubId} from "@api/doinsport/services/httpService";
import moment from "moment";

const URL = '/enzocard/events'

export const getClubAccessHistory = (clientId, page, totalRows) => _get(
  URL + '?client.id=' + clientId +
  '&page=' + page +
  '&itemsPerPage=' + totalRows
);

export const getAccessesHistory = (occurredAt) => _get(URL + '?itemsPerPage=30&club.id=' + clubId);

export const exportEvents = (from, to) =>
  _get(
    URL +
    '.export' +
    '?club.id=' + clubId
    + '&from=' + from
    + '&to=' + to
  )
;

export const getAccessesHistoryItems = (itemsPerPage = 10, page = 1, search = '', dates, extra) => {
  let route = URL +
    '?itemsPerPage=' + itemsPerPage +
    '&club.id=' + clubId +
    '&page=' + page +
    '&search=' + search
    + extra
  ;

  if (dates) {
    route += '&occurredAt[after]=' + moment(dates[0]).format('YYYY-MM-DD 00:00:00');
    route += '&occurredAt[before]=' + moment(dates[1]).format('YYYY-MM-DD 23:59:59');
  }

  return _get(route);
}
