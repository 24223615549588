<template>
  <b-card
    class="history-card"
  >
    <b-row>
      <b-col
        :align="responsiveDates ? 'right' : 'middle'"
        :class="responsiveDates ? '' : 'mb-2'"
      >

        <d-button
          text="general.actions.export"
          class="d-btn btn d-btn-default font-text-title"
          icon="fa fa-external-link mr-2"
          @on:button-click="onExport"
        />
        <d-inline-calendar
          class="ml-4"
          :model="dates"
          :label="$t(dateLabel)"
          :disabled-date="(date) => date > new Date"
          :margins="{top: responsiveDates ? '177px' : null, left: responsiveDates ? '60%' : null }"
          range
          @on:select="onDatesSelected"
        />
      </b-col>
      <b-col
        align="right"
        :cols="responsiveDates ? '3' : '12'"
      >
        <div class="has-search mr-2">
          <span class="fa fa-search form-control-feedback"></span>
          <input
            v-model="search"
            type="text"
            class="form-control"
            :placeholder="$store.getters['layout/getInnerWidth'] < 600 ? '' : $t('components.doinsport.table.search')"
            @keyup="manualFilter"
          >
        </div>
      </b-col>
    </b-row>
    <d-table
      :key="componentKey + 'table'"
      :tablefields="tableFields"
      :per-page="itemsPerPage"
      :totalRows="totalRows"
      :items="items"
      :is-busy="isBusy"
      :show-pagination="true"
      :per-page-enabled="true"
      @on:sort-by:item="onSort"
      @per-page:update="onPerPageUpdate"
      @current-page:update="onCurrentPageUpdate"
      @on:data-table-btn:show="goToCustomerDetails"
    >
    </d-table>
  </b-card>
</template>
<script>

import {exportEvents, getAccessesHistoryItems} from "@api/doinsport/services/enzocard/access-history.api";
import EnzocardAccessHistory from "@/classes/doinsport/EnzocardAccessHistory";

export default {
  data: () => ({
    componentKey: 1,
    itemsPerPage: 10,
    currentPage: 1,
    totalRows: 1,
    search: '',
    timer: null,
    dates: null,
    dateLabel: null,
    isBusy: true,
    lastOrder: null,
    items: [],
  }),
  props: {
    modalAccess: {
      type: String,
      default: 'modal-history-access-id'
    },
    display: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    display: function () {
      this.loadAccessHistory();
      this.$bvModal.show(this.modalAccess);
    }
  },
  computed: {
    responsiveDates() {
      return this.$store.getters['layout/getInnerWidth'] >= 991;
    },
    alignCellsToLeft() {
      return this.$store.getters['layout/getInnerWidth'] >= 991 ? 'text-center' : 'text-left';
    },
    tableFields() {
      return [
        {
          key: "customerAccessPreview",
          class: this.alignCellsToLeft,
          label: this.$t('views.playground.index.photo'),
          sortable: false
        },
        {
          key: "fullName",
          label: this.$t('components.custom.planning.booking-details-modal.general-information.table.about'),
          sortable: false
        },
        {
          key: "accessCode",
          class: this.alignCellsToLeft,
          label: this.$t('views.client.details.infos.sub-details.access-code'),
        },
        {
          key: "occurredAt",
          label: this.$t('views.live-accesses.index.access.date'),
          sortable: true
        },
        {
          key: "clubClientPaymentTokenOperations",
          label: this.$t('views.live-accesses.index.access.accessed-with'),
        }, {
          key: "incidence",
          label: this.$t('views.live-accesses.index.access.status'),
        },
        {
          key: "actions",
          label: this.$t("general.actions.actions")
        },
      ];
    },
  },
  methods: {
    onExport() {
      const fileName = this.$t('views.client.details.body.left.enzocard-access.history-access');
      const dateFrom = this.$moment(this.dates[0]).format('YYYY-MM-DD');
      const dateTo = this.$moment(this.dates[1]).format('YYYY-MM-DD');

      exportEvents(dateFrom, dateTo)
        .then((response) => {
          this.$downloadCSV(response.data, fileName + '_' + this.$moment().format('DD/MM/YYYY'));
        })
      ;
    },
    onSort(item) {
      if (item.sortBy !== '') {
        this.lastOrder = item;
        this.loadAccessHistory();
      }
    },
    getFilters() {
      return this.lastOrder.sortDesc ? '&order[occurredAt]=desc' : '&order[occurredAt]=asc';
    },
    onDatesSelected(dates) {
      this.dates = dates;
      const todayDate = (this.$moment(dates[0]).isSame(this.$moment(dates[1])) && this.$moment(dates[0]).format('DD/MM/YYYY') === (this.$moment().format('DD/MM/YYYY')));
      this.dateLabel = todayDate ? 'general.actions.today' : null;

      this.loadAccessHistory();
    },
    setCurrentPeriod() {
      const endDate = this.$moment().format('YYYY-MM-DD');
      const startDate = this.$moment(new Date).subtract(7, 'days');

      this.initDates(startDate, endDate);
    },
    initDates(start, end) {
      this.dates = [new Date(start), new Date(end)];
    },
    goToCustomerDetails(access) {
      let routeData = this.$router.resolve({name: 'customer_details', params: {id: access.data.user.clubClient.id}});

      window.open(routeData.href, '_blank');
    },
    onPerPageUpdate(itemsPerPage) {
      this.itemsPerPage = itemsPerPage;
      this.currentPage = 1;
      this.loadAccessHistory();
    },
    manualFilter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.loadAccessHistory();
      }, 400);
    },
    accessType(access) {
      return access.clubClientPaymentTokenOperations.length > 0 ?
        this.$t('views.client.details.body.left.enzocard-access.credit') :
        this.$t('views.client.details.body.left.enzocard-access.access-rules');
    },
    onCurrentPageUpdate(page) {
      this.currentPage = page;
      this.loadAccessHistory();
    },
    loadAccessHistory() {
      this.items = [];
      this.isBusy = true;
      let extraParams = '';

      if (this.lastOrder) {
        extraParams += this.getFilters();
      }

      getAccessesHistoryItems(this.itemsPerPage, this.currentPage, this.search, this.dates, extraParams)
        .then((response) => {
          this.totalRows = response.data['hydra:totalItems'];

          for (const item of response.data['hydra:member']) {
            const access = new EnzocardAccessHistory(item, {serialize: true});

            access.customerAccessPreview = item.user.clubClient.identityPhoto ? item.user.clubClient.identityPhoto : null;
            access.fullName = item.user.clubClient.fullName;
            access.occurredAt = this.$toTimezone(item.occurredAt).format('DD/MM/YYYY HH:mm');
            access.historyAccessType = this.accessType(item)
            access.incidence = this.$t('views.client.details.body.left.enzocard-access.list-of-incidence.' + item.incidence);
            access.actions = ['show'];
            access.clubClientPaymentTokenOperations = this.formatOperations(item.clubClientPaymentTokenOperations);
            access.data = item;

            this.items.push((access));
          }
        })
        .finally(() => {
          this.isBusy = false;
        })
      ;
    },
    formatOperations(paymentTokens) {
      let formattedOperations = '';

      for (const paymentToken of paymentTokens) {
        formattedOperations += ' ' + paymentToken.debit + ' X ' + paymentToken.paymentToken.name;
      }

      return paymentTokens.length > 0 ? formattedOperations : '-';
    }
  },
  created() {
    this.setCurrentPeriod();
    this.loadAccessHistory();
  }
}
</script>
<style lang="scss" scoped>
@import "@lazy/_b-card.scss";
@import "@lazy/client/details/_enzocard-access-details.scss";

/deep/ .con-vs-dropdown--menu {
  left: 67% !important;
  top: 156px !important;
}
</style>
