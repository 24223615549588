import {hydrate} from "@/utils/form";

export default class EnzocardAccessHistory {
  incidence = null;
  occurredAt = null;
  readerId = null;
  reference = null;
  accessCode = null;
  clubClientPaymentTokenOperations = [];
  id = null;
  operationType = null;

  constructor(object, options) {
    if ("undefined" !== typeof options) {
      if (options.serialize) {
        this.serialize(object);
      }
      if (options.deserialize) {
        this.deserialize(object);
      }
    }
  }

  serialize(object) {
    this.operationTypeAccess(object);
    hydrate(this, object);
  }

  deserialize(object) {
    hydrate(this, object);
  }

  operationTypeAccess(object) {
    for (const operation of object.clubClientPaymentTokenOperations) {
      if (object.clubClientPaymentTokenOperations.length > 0) {
        object.operationType = operation.debit + ' ' + operation.paymentToken.name;
      } else {
        object.operationType = '';
      }
    }
  }
}
